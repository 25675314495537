import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
// import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"
import { HubSpotFormComponent } from "../../components/hubspot-form-component"



const HowToCreateTwitterConversationButtonAndConversationCardForFree = ({
    location
  }) => {
  const title =
    "【最新】Twitterカンバセーションボタンを無料で作る方法｜ハッシュタグ用カンバカードとは？"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【最新】Twitterカンバセーションボタンを無料で作る方法｜ハッシュタグ用カンバカードとは？"
          pagedesc="Twitterカンバセーションボタンのメリットや無料での作成方法、気をつけるべきポイントまで紹介します。具体的な入力画面の日本語解説付きなので、すぐに自社のアカウントでカンバボタンを使ったキャンペーンが開催できます。マーケ担当者必見です。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-1.jpg"
          pagepath="/blog/how-to-create-twitter-conversation-button-and-conversation-card-for-free"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は3分で読むことができます
            </div>
            <h1>
              【最新】Twitterカンバセーションボタンを無料で作る方法｜ハッシュタグ用カンバカードとは？
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2023年7月1日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="【最新】Twitterカンバセーションボタンを無料で作る方法｜ハッシュタグ用カンバカードとは？"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-1.jpg"
                className="w-full"
              />
            </figure>
            <h2 id="id1">Twitterカンバセーションボタン作成機能が復活</h2>
            <p>
              ユーザーがハッシュタグ等の指定テキストを入力しやすくなる、カンバセーションボタン。ハッシュタグキャンペーンやクイズキャンペーン、インスタントウィン等で目にしたことがある方も多いことでしょう。できるだけ多くの参加者を集めたいプレゼント企画において、ユーザーの目を引き、さらに参加ハードルを下げてくれるカンバセーションボタンは強力な存在です。
            </p>
            <p>
              一時的に、カンバセーションボタンは管理画面から作成ができない状態になっていました。その結果、有料ツールを導入しTwitter
              API経由で作成せざるを得ず、マーケティング担当者にとっては大きな課題でした。
            </p>
            <p>
              しかし、現在ではTwitterと提携するツールが登場し、有料ツール不要でカンバセーションボタンが作れるようになりました。
            </p>
            <h2 id="id2">
              カンバセーションボタンでキャンペーン効果をさらにUP！
            </h2>
            <p>
              この記事では、カンバセーションボタンの利点や詳しい作成方法を紹介します。多くのターゲット層にアクセス可能なTwitterを利用し、効果的なキャンペーンの開催に活用してみてください。
            </p>

            <p>この記事は次のような人にオススメです！</p>
            <ul className="blog__border-list">
              <li>ハッシュタグキャンペーンを盛り上げたい企業</li>
              <li>
                カンバセーションボタンを使って、ユーザーの投稿を促したい販促担当者
              </li>
              <li>最新のカンバセーションボタンの作成方法を知りたい方</li>
            </ul>
            <p>
              ※記載内容は、当記事公開時の情報に基づきます。最新の情報については、
              <a
                href="https://business.twitter.com/ja/help/campaign-setup/conversation-buttons.html"
                target="_blank"
                rel="noreferrer"
              >
                Twitter「カンバセーションボタン」ページ
              </a>
              をご確認ください。
            </p>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    Twitterカンバセーションボタン作成機能が復活
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    カンバセーションボタンでキャンペーン効果をさらにUP！
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    1. Twitterカンバセーションボタンとは？
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        Twitterでユーザーが投稿しやすくする機能
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        カンバセーションボタンを使うメリット
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        カンバセーションボタンは無料で作れる？
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        カンバセーションボタン利用のキャンペーンアイデア
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id8" className="toc__link">
                    2. Twitterカンバセーションボタンの作成方法
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        カンバセーションボタン作成の準備物
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        大まかな作成の流れ
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        【手順1】カンバセーションカードを作成する
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        【手順2】カンバセーションカードを使って投稿する（カンバセーションボタン投稿）
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id13" className="toc__link">
                        【補足】参加者からの見え方
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id14" className="toc__link">
                    3. Twitterカンバセーションボタン利用時の注意点
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id15" className="toc__link">
                        Twitter広告アカウントが必須
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id16" className="toc__link">
                        ハッシュタグ投稿の収集は自動化がおすすめ
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id17" className="toc__link">
                        引用RTにはできないため、収集上限に注意が必要
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id18" className="toc__link">
                    まとめ：カンバセーションボタンを活用してキャンペーンをさらに拡散！
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id19" className="toc__link">
                        ユーザーが楽しめる企画で参加を促す
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id20" className="toc__link">
                        ハッシュタグの収集依頼をするなら
                      </a>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id3">1. Twitterカンバセーションボタンとは？</h2>
              <p>
                まずカンバセーションボタンの概要と、なぜキャンペーンで利用されるのかをおさえておきましょう。
              </p>
              <p>
                カンバセーションボタンは、以前は「カンバセーショナルカード」と呼ばれていました。他にも、「カンバカード」「カンバボタン」と呼ばれることもあります。
              </p>

              <h3 id="id4">Twitterでユーザーが投稿しやすくする機能</h3>
              <div className="blog__border-highlight">
                <p>
                  メッセージを見た利用者が自分のフォロワーに拡散したいという気持ちをかき立てるため、メッセージのリーチやエンゲージメントが拡大します。
                  <br />（
                  <a
                    href="https://business.twitter.com/ja/help/campaign-setup/conversation-buttons.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitter「カンバセーション」ページ
                  </a>
                  より）
                </p>
              </div>
              <p>
                端的に言えば、カンバセーションボタンとは、ユーザーが投稿を簡単に行える機能のことです。最大4つの選択肢がボタン形式で表示され、ユーザーがどれかを選んで投稿できるものです。
              </p>

              <h3 id="id5">カンバセーションボタンを使うメリット</h3>
              <p>
                普通にツイート投稿するよりも少しだけ手間がかかるカンバセーションボタンですが、さまざまなメリットがあります。
              </p>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ／
                  <a href="https://twitter.com/hashtag/%E3%83%97%E3%83%AC%E3%82%BC%E3%83%B3%E3%83%88%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%9A%E3%83%BC%E3%83%B3?src=hash&amp;ref_src=twsrc%5Etfw">
                    #プレゼントキャンペーン
                  </a>
                  スタート！
                  <a href="https://twitter.com/hashtag/QUO%E3%82%AB%E3%83%BC%E3%83%89Pay?src=hash&amp;ref_src=twsrc%5Etfw">
                    #QUOカードPay
                  </a>
                  1,000円分が当たる🎯
                  <br />＼ <br />
                  <br />
                  夏はもうすぐ！
                  <br />
                  あなたの夏の予定を教えてください🌴
                  <br />
                  <br />
                  【応募方法】
                  <br />
                  1️⃣
                  <a href="https://twitter.com/PARKLoT1?ref_src=twsrc%5Etfw">
                    @PARKLoT1
                  </a>
                  をフォロー
                  <br />
                  2️⃣ 下のボタンから
                  <a href="https://twitter.com/hashtag/%E3%82%A4%E3%83%B3%E3%82%B9%E3%82%BF%E3%83%B3%E3%83%88%E3%82%A6%E3%82%A3%E3%83%B3%E3%81%AFPARKLoT?src=hash&amp;ref_src=twsrc%5Etfw">
                    #インスタントウィンはPARKLoT
                  </a>{" "}
                  をつけて
                  <br />
                  3️⃣ 夏の予定🌴と一緒に投稿！
                </p>
                &mdash;
                【公式】PARKLoT🎯インスタントウィンを最速で実施できるキャンペーンツール
                (@PARKLoT1)
                <a href="https://twitter.com/PARKLoT1/status/1673158954904330241?ref_src=twsrc%5Etfw">
                  June 26, 2023
                </a>
              </blockquote>
              <ul>
                <li>
                  <span className="font-bold">
                    目立つボタンがユーザの注意を引く
                  </span>
                  <p>
                    上記の例でも分かるように、ぐるっと囲まれた「Tweet#インスタントウィンならPARKLoT」のボタンは目を引きます。次から次へと新しい投稿が流れるタイムラインにおいて、ユーザーの視線を引きつけることは、カンバセーションボタンの一つの大きな利点です。
                  </p>
                </li>
                <li>
                  <span className="font-bold">
                    ユーザーがハッシュタグ投稿をしやすい
                  </span>
                  <p>
                    ユーザーが「Tweet#インスタントウィンならPARKLoT」を押すと、自動的に「#インスタントウィンならPARKLoT」というハッシュタグが含まれた状態でツイートする画面へ遷移します。これはハッシュタグ投稿の手間を省くだけでなく、スペルミスなどによる応募失敗のリスクを減らすことにもつながります。
                  </p>
                </li>
                <li>
                  <span className="font-bold">
                    キャンペーンが拡散しやすくなる
                  </span>
                  <p>
                    投稿が簡単になることで、参加率の向上が期待できます。特にTwitterはその拡散性が特徴のSNSメディアです。ユーザーに自社を取り上げた投稿をしてもらったり、ツイートを拡散してもらうことで、認知度が広がっていきます。
                  </p>
                </li>
                <li>
                  <span className="font-bold">
                    お礼のメッセージが表示できる
                  </span>
                  <p>
                    カンバセーションボタンを通じて投稿したユーザーには、URLとお礼メッセージが表示できます。（画像赤枠）さらに、表示されるカードそのものが指定URLへのリンクになるため、おすすめしたいLPやサイトへの遷移を促すことが可能です。（画像青枠）
                  </p>
                </li>
              </ul>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="カンバセーションボタンの例"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-2.png"
                maxwidth={640}
              />

              <h3 id="id6">カンバセーションボタンは無料で作れる？</h3>
              <p>
                たくさんの利点があるカンバセーションボタン、ぜひ自社でも使ってみたくなったことかと思います。
              </p>
              <p>
                カンバセーションボタンは、Twitter広告アカウントがあれば無料で作成可能です。
                <br />
                広告アカウントの作成には、クレジットカードの登録が必要です。
              </p>
              <p>
                詳しくは、
                <a
                  href="https://business.twitter.com/ja/help/account-setup/ads-account-creation.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter「広告アカウントの作成」ページ
                </a>
                を参照ください。
              </p>

              <h3 id="id7">カンバセーションボタン利用のキャンペーンアイデア</h3>
              <p>
                以下のようなキャンペーンにカンバセーションボタンを使うことで、効果を高めることができます。
              </p>
              <p>【ボタン選択肢が1つ】</p>
              <ul>
                <li>
                  指定ハッシュタグ＆コメント
                  <p>
                    <a
                      href="https://twitter.com/PARKLoT1/status/1673158954904330241?s=20"
                      target="_blank"
                      rel="noreferrer"
                    >
                      上記PARKLoTの例
                    </a>
                    では、この形式を採用しました。どのユーザーにも同じハッシュタグをつけてもらい、追加でコメントをしてもらう流れです。メリットは、ユーザーがどの選択肢にするか迷いにくいことです。
                  </p>
                </li>
              </ul>
              <p>【ボタン選択肢が複数】</p>
              <ul>
                <li>
                  ハッシュタグでクイズに回答
                  <p>
                    キャンペーンツイートでクイズを出題し、用意した選択肢の中から答えを選んでもらう形式です。
                  </p>
                </li>
                <li>
                  ハッシュタグでアンケートに回答
                  <p>
                    キャンペーンツイートで商品を紹介してどれが欲しいか選んでもらう、ブランドの好きなところを1つ選んでもらう、などの形式にも利用できます。
                  </p>
                </li>
                <li>
                  ハッシュタグで投票に参加
                  <p>
                    ハッシュタグ投稿が1番多かったアイデアを商品化など、ユーザー参加型の商品開発にもカンバセーションボタンは有効です。繰り返し同じツイートをさせることは
                    <a
                      href="https://help.twitter.com/ja/rules-and-policies/twitter-contest-rules"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Twitterのポリシー
                    </a>
                    で禁止されているので、企画設計にはご注意ください。
                  </p>
                </li>
              </ul>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id8">2. Twitterカンバセーションボタンの作成方法</h2>
              <p>
                カンバセーションボタンを使うとどんなメリットがあるのか、どんなキャンペーンができるのかを説明しました。キャンペーンのイメージがついたところで、ここからカンバセーションボタンの実際の作成方法を解説します。
              </p>

              <h3 id="id9">カンバセーションボタン作成の準備物</h3>
              <p>
                カンバセーションボタン作成のためには、以下の3点を準備してください。
              </p>
              <table>
                <thead>
                  <tr>
                    <th className="blog__text-center">準備物</th>
                    <th className="blog__text-center">仕様</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold">1. クリエイティブ</td>
                    <td>
                      【画像】
                      <br />
                      ・推奨最小横幅: 800ピクセル
                      <br />
                      ・縦横比: 1.91:1（800×418など）
                      <br />
                      ・ファイル形式:
                      JPEG、PNG、またはアニメーションでないGIF画像
                      <br />
                      ・ファイルサイズ: 最大3MB
                      <br />
                      <br />
                      【動画】
                      <br />
                      ・動画の長さ:15秒以下を推奨。最大: 2分20秒。
                      <br />
                      ・ファイル形式: MP4またはMOV
                      <br />
                      ・ファイルサイズ:
                      最適なパフォーマンスを得るには、ファイルを30MB未満にする。最大で1GB。
                      <br />
                      ・ブランディング:
                      動画全体を通して表示することを強く推奨。ロゴを用いる場合、左上隅に固定。
                      <br />
                      ・キャプション:
                      クローズドキャプションまたはテキストオーバーレイを強く推奨。
                      <br />
                      ・詳細は
                      <a
                        href="https://business.twitter.com/ja/help/campaign-setup/creative-ad-specifications.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Twitter広告クリエイティブの仕様ページ
                      </a>
                      をご覧ください。
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">2. ハッシュタグ</td>
                    <td>
                      ・ハッシュタグ文字を含めて21文字（すべて日本語の全角文字にした場合、ハッシュタグ文字を含めて11文字）
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">3. 投稿文</td>
                    <td>
                      ・最大280文字（すべて日本語の全角文字にした場合は140文字）
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                基本的には画面の項目に沿って入力すれば大丈夫です。ただし、ハッシュタグと投稿文は事前にドキュメントにまとめてコピー＆ペーストできるようにしておくと、ミスが減ってよいでしょう。
              </p>

              <h3 id="id10">大まかな作成の流れ</h3>
              <p>
                カンバセーションボタンを使った投稿をするためには、以下の流れで進めます。
              </p>
              <ol>
                <li>
                  キャンペーンを開催するアカウントのTwitter広告アカウントにログインする
                </li>
                <li>
                  <a
                    href="https://business.twitter.com/ja/help/campaign-setup/conversation-buttons.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterページ
                  </a>
                  より、
                  <a
                    href="https://composer.bn.co/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Brand Networks社のツイート作成ツール
                  </a>
                  にアクセスする
                </li>
                <li>カンバセーション「カード」を作成する</li>
                <li>
                  カンバセーションカードを使って、カンバセーションボタン付きの投稿をする
                </li>
              </ol>

              <h3 id="id11">【手順1】カンバセーションカードを作成する</h3>
              <p>
                Twitter広告アカウントにログインした状態で
                <a
                  href="https://composer.bn.co/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Brand Networks社のツイート作成ツール
                </a>
                にアクセスすると、以下の画面になります。
              </p>
              <p>
                広告アカウントを選び、「Create a Conversation
                Card（カンバセーションカードを作成する）」を選択します。
              </p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="Brand Networks社のツイート作成ツールにアクセスする"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-3.png"
                maxwidth={640}
              />
              <br />
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="Create Conversation Cardを選択する"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-4.png"
                maxwidth={640}
              />

              <p>カンバセーションカード作成画面が表示されます。</p>
              <p>
                必要な項目に記入し、「Create
                Card」を選択すると、カード作成成功の画面に進みます。
              </p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="必要な項目を入力する"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-5.png"
                maxwidth={640}
              />
              <p>
                Twitterは
                <a
                  href="https://help.twitter.com/ja/rules-and-policies/twitter-contest-rules#:~:text=%E4%B8%BB%E5%82%AC%E8%80%85%E3%81%AE%40%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E5%90%8D%E3%82%92%E5%90%AB%E3%82%81%E3%81%A6%E3%83%84%E3%82%A4%E3%83%BC%E3%83%88%E3%81%97%E3%81%A6%E3%82%82%E3%82%89%E3%81%86"
                  target="_blank"
                  rel="noreferrer"
                >
                  主催者の@ユーザー名を含めてツイートしてもらう
                </a>
                ことを推奨しているため、「Tweet prompt
                #hashtag」にはハッシュタグの他に開催アカウント名（例 @PARKLoT1
                ）を入れると尚良いでしょう。
              </p>
              <p>
                （選択肢が商品の場合、おすすめポイントも入るように設定するとさらに販促効果が期待できます。その場合、半角スペースを忘れずに追加しましょう。例
                「プレミアムコットンで極上の着ごこち！ #商品A
                に応募しました！」）
              </p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="Viewを選択する"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-6.png"
                maxwidth={640}
              />
              <p>
                そのまま「View」を選択すると、作成したカードを確認できます。
              </p>
              <p>カードは、閲覧・投稿に使用・編集・複製・削除が可能です。</p>
              <p>
                （カードを使って投稿した後も、ハッシュタグなどを変更することができます。しかし、変更の反映まで30〜1時間程度かかるため、事前にタイプミスがないか確認しましょう。）
              </p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="作成したカードの確認画面"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-7.png"
                maxwidth={640}
              />

              <h3 id="id12">
                【手順2】カンバセーションカードを使って投稿する（カンバセーションボタン投稿）
              </h3>
              <p>カード編集画面で「Create Tweet」チェックを選択します。</p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="Create Tweetチェックを選択する"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-8.png"
                maxwidth={640}
              />
              <p>投稿文入力、投稿日時の設定をします。</p>
              <p>
                設定が完了したら、「Update Card + Create New
                Tweet」を選択して投稿（予約）します。
              </p>
              <p>
                （投稿後もハッシュタグ等の変更は可能ですが、投稿文は編集できません。
                ハッシュタグ部分の半角スペースもれや締切日時等があっているか、入念にチェックしてください。）
              </p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="投稿文入力、投稿日時の設定をする"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-9.png"
                maxwidth={640}
              />
              <p>正常に投稿されたことが表示されます。</p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="正常に投稿されたことが表示される"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-10.png"
                maxwidth={640}
              />
              <p>このように投稿されます。</p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="投稿されるイメージ"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-11.png"
                maxwidth={640}
              />

              <h3 id="id13">【補足】参加者からの見え方</h3>
              <p>
                ユーザーがカンバセーションボタンを押すと、投稿の下書き画面に進みます。
              </p>
              <p>
                下書きには、自動でハッシュタグとカード内容が挿入されています。
              </p>
              <p>
                （カンバセーションボタンを使って投稿した場合、カードの情報も一緒に投稿されます。）
              </p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="ユーザーがカンバセーションボタンを押すと投稿の下書き画面に進む"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-12.png"
                maxwidth={640}
              />
              <br />
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="カンバセーションボタンから投稿の場合はカード情報の表示あり"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-13.png"
                maxwidth={640}
              />
              <br />
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="ハッシュタグ手打ちの場合はカード情報の表示なし"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-14.png"
                maxwidth={640}
              />
              <br />
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="ハッシュタグ手打ちの投稿とカンバセーションボタンからの投稿のイメージ"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230701/230701-15.png"
                maxwidth={640}
              />
              <p>
                （ユーザー投稿後は、お礼メッセージと指定URLへ変更されています。このカードをクリックした場合、表示されているURLへ進みます。元のキャンペーン投稿へは進みません。）
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/cost-effectiveness-of-twitter-instant-win"
                    target="_blank"
                    rel="noreferrer"
                  >
                    上司を説得！Twitterインスタントウィンの費用対効果まとめ
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id14">3. Twitterカンバセーションボタン利用時の注意点</h2>
              <p>
                カンバセーションボタンを利用する際に、注意しておきたいポイントがあります。
              </p>

              <h3 id="id15">Twitter広告アカウントが必須</h3>
              <p>
                前述した通り、カンバセーションボタンの作成にはTwitter広告アカウントの開設が必要です。早めに対応しておきましょう。
              </p>

              <h3 id="id16">ハッシュタグ投稿の収集は自動化がおすすめ</h3>
              <p>
                Twitterでハッシュタグ投稿を手動で検索し確認するのは、非常に大変です。せっかくユーザーが投稿してくれたものを見逃してしまうのは避けたいものです。この問題を解決するためには、自動収集ツールを利用してハッシュタグ投稿を一覧化するのが良いでしょう。どのようなアカウントから応募があったかを把握し分析することで、次回のキャンペーンへの改善点を見つけることも可能となります。
              </p>

              <h3 id="id17">引用RTにはできないため、収集上限に注意が必要</h3>
              <p>
                自動収集ツールでハッシュタグ投稿の一覧化をするには、Twitter
                APIのBasicプラン以上の契約が必要です。Basicプランに課金して実施する場合、各開催アカウントにつき月間最大1万件までの収集上限があることに注意が必要です。
              </p>
              <p>
                1万件以上の投稿が見込まれる場合は、引用リツイート形式にしてキャンペーン投稿と紐づける、もしくはTwitter
                API Proプラン以上を契約するといった対策が必要です。
              </p>
              <p>
                （カンバセーションボタンを使った場合、その投稿は「オーガニック投稿」となります。これは何の投稿にも紐づいていないということで、使用されたハッシュタグを対象にツールが自動収集します。一方、引用リツイートの場合はキャンペーン元の投稿に対するツイートになるので、投稿間の連携関係が発生します。この場合、ツールはキャンペーン元投稿に対する引用リツイートを収集し、さらにハッシュタグがついていることで応募条件を満たすというフローになります。）
              </p>
            </div>
            <div>
              <h2 id="id18">
                まとめ：カンバセーションボタンを活用してキャンペーンをさらに拡散！
              </h2>
              <h3 id="id19">ユーザーが楽しめる企画で参加を促す</h3>
              <p>
                カンバセーションボタンを使ったハッシュタグキャンペーンは、ユーザーに楽しんでもらえる上にブランド認知も高まる企画です。上記の作成手順を参考に、ぜひ自社でのキャンペーン開催に組み込んでみてください。
              </p>

              <h3 id="id20">ハッシュタグの収集依頼をするなら</h3>
              <p>
                <a
                  href="https://www.park-lot.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  PARKLoT（パークロット）
                </a>
                は、ハッシュタグ自動収集にも対応しています。回数無制限でインスタントウィンを開催できる機能もあるため、インスタントウィン形式のフォロー＆リツイートでフォロワーを確立し、その後にカンバセーションボタンを使ったハッシュタグキャンペーンでファン増やすことができます。
                <a
                  href="https://hub.park-lot.com/free-consulting"
                  target="_blank"
                  rel="noreferrer"
                >
                  無料相談ページ
                </a>
                より、ぜひお気軽にお問い合わせください。
              </p>
            </div>
            <br />
            {/* HubSpotのフォームを追加 */}
            <HubSpotFormComponent />
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            {/* <div className="case_study main">
              <CvBtn />
            </div> */}
            <br />
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/cost-effectiveness-of-twitter-instant-win"
                    target="_blank"
                    rel="noreferrer"
                  >
                    上司を説得！Twitterインスタントウィンの費用対効果まとめ
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/rise-engagement-rate-with-retweet-or-like-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    驚きのエンゲージメント率！
                    リツイートいいねキャンペーンの魅力を徹底解説
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/campaign-for-food-and-restaurants"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【食品・飲食店特化】売上アップ！集客キャンペーン事例をわかりやすく解説
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-sns-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SNSプロモーションを成功させるには？成功事例から学べる4つのポイント
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-to-reduce-cost-of-sns-operations"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【Twitter企業アカウント】うまく運用代行を活用して業務コストを削減する方法とは？
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default HowToCreateTwitterConversationButtonAndConversationCardForFree
